<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
<!--      <img-->
<!--           style="height: 48px;"-->
<!--           src="~@/assets/images/just_logo.svg" alt="">-->
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <TheHeaderDropdownAccount/>
    </CHeaderNav>
    <CSubheader class="px-3">
      <WBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccount from './TheHeaderDropdownAccount'
import WBreadcrumbRouter from '../views/components/WBreadcrumbRouter'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccount,
    WBreadcrumbRouter,
  }
}
</script>
