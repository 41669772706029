<template>
  <CBreadcrumb v-bind="props">
    <slot></slot>
  </CBreadcrumb>
</template>

<script>

export default {
  name: 'WBreadcrumbRouter',
  props: {
    addClasses: [String, Array, Object],
    addLinkClasses: [String, Array, Object],
    addLastItemClasses: [String, Array, Object]
  },
  computed: {
    items() {
      const routes = this.$route.matched.filter(route => {
        return route.name || (route.meta && route.meta.label)
      })
      return routes.map(route => {
        const meta = route.meta || {}
        const text = meta.label || route.name
        if (this._.has(meta, 'label') && meta.label === '') {
          return false
        }
        return {
          to: route,
          text: this.$t(text)
        };
      }).filter(route => {
        if (route) {
          return true
        }
      });
    },
    props() {
      return {
        items: this.items,
        addClasses: this.addClasses,
        addLinkClasses: this.addLinkClasses,
        addLastItemClasses: this.addLastItemClasses
      }
    }
  }
}
</script>
