<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1"></span>
    </div>
    <div class="mfs-auto">
      <span class="">&copy; {{new Date().getFullYear()}} {{ $t(`${$config.profile.name}.footerText`) }}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
