<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img class="c-sidebar-brand-full"
           v-show="$config.profile.name === 'profile_almashreq'"
           style="margin: 5px 0; width: 100%;"
           src="~@/assets/images/profile_almashreq/logo.png" alt="">

      <img class="c-sidebar-brand-full"
           v-show="$config.profile.name === 'profile_alhammad'"
           style="margin: 5px 0; width: 70%;"
           src="~@/assets/images/profile_alhammad/logo.svg" alt="">

      <img class="c-sidebar-brand-minimized"
           v-show="$config.profile.name === 'profile_almashreq'"
           style="margin: 5px 0; width: 48px;"
           src="~@/assets/images/profile_almashreq/just-logo.png" alt="">

      <img class="c-sidebar-brand-minimized"
           v-show="$config.profile.name === 'profile_alhammad'"
           style="margin: 5px 0; width: 48px;"
           src="~@/assets/images/profile_alhammad/just_logo_blue.svg" alt="">
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
export default {
  name: 'TheSidebar',
  data() {
    return {

    }
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    },
    nav() {
      return  [
        {
          _name: 'CSidebarNav',
          _children: [
            {
              _name: 'WebatySidebarNavItem',
              name: this.$t('dashboard'),
              to: this.$router.resolve({ name: 'UserHome' }).href,
              icon: 'cil-speedometer',
            },
            {
              _name: 'WebatySidebarNavItem',
              name: this.$t('settings'),
              to: this.$router.resolve({ name: 'Settings' }).href,
              fontIcon: 'wy-icon-gear',
            },
            {
              _name: 'WebatySidebarNavItem',
              name: this.$t('users'),
              to: this.$router.resolve({ name: 'Users' }).href,
              fontIcon: 'wy-icon-users',
            },
            {
              _name: 'WebatySidebarNavItem',
              name: this.$t('products'),
              to: this.$router.resolve({ name: 'Products' }).href,
              fontIcon: 'wy-icon-products',
            },
            {
              _name: 'WebatySidebarNavItem',
              name: this.$t('customers'),
              to: this.$router.resolve({ name: 'Customers' }).href,
              fontIcon: 'wy-icon-clients',
            },
            {
              _name: 'WebatySidebarNavItem',
              name: this.$t('gas_stations'),
              to: this.$router.resolve({ name: 'GasStations' }).href,
              fontIcon: 'wy-icon-gas-station',
            },
            {
              _name: 'WebatySidebarNavItem',
              name: this.$t('invoices'),
              to: this.$router.resolve({ name: 'Invoices' }).href,
              fontIcon: 'wy-icon-invoice',
            },
          ]
        }
      ];
    }
  }
}
</script>
