<template>
  <div class="c-app wy-dashboard-layout">

    <CModal
        color="red"
        centered
        :show.sync="$store.state.confirmationModalIsOpen"
        :closeOnBackdrop="false"
    >
      {{ $store.state.confirmationModalMessage }}
      <template #header-wrapper>&nbsp;</template>
      <template #footer>
        <button
            type="button"
            class="btn btn-secondary"
            @click="$store.commit('closeConfirmationModal')"
        >
          {{ $t('no') }}
        </button>
        <button
            type="button"
            class="btn btn-red"
            @click="$store.state.confirmationModalOnOk(); $store.commit('closeConfirmationModal');"
        >
          {{ $t('yes') }}
        </button>
      </template>
    </CModal>

    <CModal
        color="red"
        centered
        :show.sync="authorizationModalIsOpen"
        :closeOnBackdrop="false"
        class="text-center"
    >
      {{ $store.state.globalAuthorizationError }}
      <template #header-wrapper>&nbsp;</template>
      <template #footer>
       <div style="width: 100%;">
         <button
             type="button"
             class="btn btn-red"
             style="margin: 0 auto;"
             @click="closeAuthorizationModal"
         >
           {{ $t('ok') }}
         </button>
       </div>
      </template>
    </CModal>

    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  data() {
    return {
      authorizationModalIsOpen: false,
    };
  },
  watch: {
    '$store.state.globalAuthorizationError': function (globalAuthorizationError) {
      this.authorizationModalIsOpen = !this._.isEmpty(globalAuthorizationError);
    },
  },
  methods: {
    closeAuthorizationModal() {
      // this.$router.push({ name: 'UserHome' });
      this.$logout(this);
      this.$store.commit('clearGlobalAuthorizationError');
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
