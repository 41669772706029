<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar" style="background-color: #EEEEEE; padding-top: 3px;">
          <img
            src="~@/assets/images/user-avatar.svg"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>{{ $t('account') }}</strong>
    </CDropdownHeader>
    <CDropdownItem @click="changeLanguage()">
      <CIcon name="cil-globe-alt" />
      <span v-if="$store.state.languageCode === 'en'" class="arabic-font">العربية</span>
      <span v-if="$store.state.languageCode === 'ar'" class="english-font">English</span>
    </CDropdownItem>
    <CDropdownItem @click="$router.push({ name: 'ChangePassword' })">
      <i class="wy-icon-password"></i> {{ $t('change_my_password') }}
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> {{ $t('logout') }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccount',
  methods: {
    logout() {
      this.$store.commit('confirmation', {
        message: this.$t('are_you_sure_that_you_want_to_logout'),
        onOk: () => {
          this.$logout(this);
        }
      });
    },
    changeLanguage() {
      const params = this.$route.params;
      params.languageCode = this.$store.state.languageCode === 'en' ? 'ar' : 'en';
      this.$router.push({ name: this.$route.name, params, query: this.$route.query })
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
